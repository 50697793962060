import React, { useState } from "react";
import Papa from "papaparse";
import "./index.css";

const MovieData = () => {
    const [data, setData] = useState({});
    Papa.parse("https://docs.google.com/spreadsheets/d/e/2PACX-1vRScbf0_cFmJLq0nbhJrvBCn5OUO9hTHCnSQooYMQubLNHvRZI0f2bbMH8Bri42uq_IPyGyW3ZMl-Aj/pub?gid=790410770&single=true&output=csv", {

        download: true,
        header: true,
        complete: (results) => {
            setData(results.data);
        },
    });
    const movies = Array.from(data);

    return (
        <ol>
            {movies.map((data) => (
                <li key={data.movie}>
                    {data.Название} | {data.Тип} ({data.Год}) - Для {data.Для}
                </li>
            ))}
        </ol>
    );
};

export default MovieData;
